var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      "\n  Your " +
        _vm._s(_vm.serviceName) +
        " will be " +
        _vm._s(_vm.action) +
        " " +
        _vm._s(_vm.untilTheEndDate) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }