<template>
  <div>
    Your {{ serviceName }} will be {{ action }} {{ untilTheEndDate }}
  </div>
</template>

<script>
import { returnDateForBrowserTimezone } from "@/common/modules/formatters"

export default {
  name: "DomainServiceRemovalTimer",
  props: {
    action: String,
    serviceName: String,
    actionDateString: String,
    actionTimeFrame: Number,
  },
  data(){
    return {
      untilTheEndDate: '',
      distance: '',
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
    }
  },
  mounted(){
    setInterval(() => {
      this.timer()
    }, 1000)
  },
  methods: {
    returnDateForBrowserTimezone: returnDateForBrowserTimezone,
    timer() {
      this.setDistance()
      this.setTimeIntervals()
      this.untilTheEndDate = this.days > 0 ? `in ${this.days}d ${this.hours}h` : 'soon'
    },
    setTimeIntervals() {
      this.days = Math.floor(this.distance / (1000 * Math.pow(60, 2) * 24))
      this.hours = Math.floor((this.distance % (1000 * Math.pow(60, 2) * 24)) / (1000 * Math.pow(60, 2)))
      this.minutes = Math.floor((this.distance % (1000 * Math.pow(60, 2))) / (1000 * 60))
      this.seconds = Math.floor((this.distance % (1000 * 60)) / 1000)
    },
    setDistance() {
      const endDate = this.returnDateForBrowserTimezone(this.actionDateString).ts
      const now = new Date().getTime()
      this.distance = endDate - now
    },
  },
}
</script>

<style scoped>

</style>
