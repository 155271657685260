<template>
  <div v-if="loading">
    <ct-centered-spinner />
  </div>
  <div v-else>
    <b-alert
      :variant="getServiceActionVariant()"
      :class="`mb-4 ${getServiceActionAlertClass()}`"
      show
    >
      <b-icon
        icon="exclamation-circle"
        :class="`mr-2 text-${getServiceActionVariant()} circle-icon`"
      />

      <strong>
        <domain-service-removal-timer
          :action="getNextServiceAction()"
          :service-name="service.product.name"
          :action-date-string="getNextServiceActionDate()"
          :action-time-frame="getNextServiceActionTimeFrame()"
        />
      </strong>
    </b-alert>
  </div>
</template>

<script>

import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { mapActions } from 'vuex'
import DomainServiceRemovalTimer from '@/components/Domains/DomainServiceRemovalTimer.vue'

export default {
  name: "DomainServicePendingRemoval",
  components: { DomainServiceRemovalTimer, CtCenteredSpinner },
  props: {
    service:  {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      currentTime: null,
      loading: false,
      inProcess: false,
      showReactivationConfirmWindow: false,
    }
  },
  methods: {
    ...mapActions('products', ['loadProductByParams']),
    getNextServiceAction() {
      const serviceAction = {
        'new': 'suspended',
        'suspended': 'deleted permanently',
      }

      return serviceAction[this.service.cancellation_status] || null
    },
    getNextServiceActionDate() {
      const actionDate = {
        'new': this.service.suspend_date,
        'suspended': this.service.delete_date,
      }

      return actionDate[this.service.cancellation_status] || null
    },
    getNextServiceActionTimeFrame() {
      const actionTimeFrame = {
        'new': 15,
        'suspended': 45,
      }

      return actionTimeFrame[this.service.cancellation_status] || null
    },
    getServiceActionVariant() {
      const actionVariant = {
        'new': 'warning',
        'suspended': 'danger',
      }

      return actionVariant[this.service.cancellation_status] || null
    },
    getServiceActionAlertClass(){
      const actionAlertClass = {
        'new': 'expire-notification-warning',
        'suspended': 'expire-notification-danger',
      }

      return actionAlertClass[this.service.cancellation_status] || null
    },
  },
}
</script>

<style lang="scss">
  @mixin flexbox-border($color: orange) {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-left: 5px solid $color;
  }

  .circle-icon {
    width: 2em; height: 2em;
  }

  .expire-notification-warning {
    @include flexbox-border();
  }

  .expire-notification-danger {
    @include flexbox-border($color: red);
  }

</style>
